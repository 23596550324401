import React, { useState } from 'react';
import AppBar from '@material-ui/core/AppBar';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Container from '@material-ui/core/Container';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import GitHubIcon from '@material-ui/icons/GitHub';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import Link from '@material-ui/core/Link';
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles';
import Moment from 'react-moment';
import moment from 'moment';
import uuid from 'react-uuid';

const useStyles = makeStyles((theme) => ({
  '@global': {
    ul: {
      margin: 0,
      padding: 0,
      listStyle: 'none',
    },
  },
  appBar: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },
  toolbar: {
    flexWrap: 'wrap',
  },
  toolbarTitle: {
    flexGrow: 1,
  },
  link: {
    margin: theme.spacing(1, 1.5),
  },
  heroContent: {
    padding: theme.spacing(8, 0, 6),
  }
}));

function Copyright() {
  return (
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://sean-harrison.net/">
        Sean Harrison
      </Link>{' '}
      {new Date().getFullYear()}
    </Typography>
  );
}

function App() {

  const classes = useStyles();

  const theme = createMuiTheme({
    palette: {
      type: 'dark',
    },
  });

  function addCommandLine(userType, textMessage) {
    setLines(lines => [...lines, { id: uuid(), time: new moment(), user: userType, text : textMessage}]);
  }

  const handleKeyDown = (event) => {

    if (event.key === 'Enter') {
      addCommandLine("usr", commandLineInput);

      var recognisedCommand = false;

      if (commandLineInput.toLowerCase() === "clear") {
        setLines(defaultLines);
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase().includes("sudo")) {
        addCommandLine("sys", ">> permission denied <<");
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "ls") {
        addCommandLine("sys", ">> /cookies.txt");
        addCommandLine("sys", ">> /secrets.txt");
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "nano secrets.txt" 
            || commandLineInput.toLowerCase() === "nano /secrets.txt") {
        addCommandLine("sys", ">> my password is password123");
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "nano cookies.txt"
          || commandLineInput.toLowerCase() === "nano /cookies.txt") {
        addCommandLine("sys", ">> step 1 // preheat oven to 375 degrees F (190 degrees C)");
        addCommandLine("sys", ">> step 1.1 // in a small bowl stir together flour, baking soda and baking powder after set aside");
        addCommandLine("sys", ">> step 2 // in a large bowl cream together the butter and sugar until smooth. beat in egg and vanilla");
        addCommandLine("sys", ">> step 2.1 // gradually blend in the dry ingredients");
        addCommandLine("sys", ">> step 2.2 // roll rounded teaspoonfuls of dough into balls and place onto ungreased cookie sheets");
        addCommandLine("sys", ">> step 3 // bake 8 to 10 minutes in the preheated oven or until golden");
        addCommandLine("sys", ">> step 3.1 // let stand on cookie sheet two minutes before removing to cool on wire racks");
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "hello") {
        addCommandLine("sys", ">> hello usr!!!");
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "build") {
        addCommandLine("sys", ">> This site was built using React and is hosted using AWS Amplify");
        addCommandLine("sys", ">> Amplify provides CI/CD that automatically deploys any changes made to this sites GitHub repository");
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "github") {
        addCommandLine("sys", ">> opening my github profile in a new tab");
        window.open('https://github.com/seanaharrison')
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "linkedin") {
        addCommandLine("sys", ">> opening my linkedin profile in a new tab");
        window.open('http://ie.linkedin.com/in/seanaharrison')
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "twitter") {
        addCommandLine("sys", ">> opening my twitter profile in a new tab");
        window.open('https://twitter.com/sean__harrison')
        recognisedCommand = true;
      }

      if (commandLineInput.toLowerCase() === "help") {
        addCommandLine("sys", ">> build [tech behind this site]");
        addCommandLine("sys", ">> clear [reset the console]");
        addCommandLine("sys", ">> hello [say hello]");
        addCommandLine("sys", ">> help [print this menu]");
        addCommandLine("sys", ">> github [opens my github profile]");
        addCommandLine("sys", ">> linkedin [opens my linkedin profile]");
        addCommandLine("sys", ">> ls [list directory]");
        addCommandLine("sys", ">> nano [read file]");
        addCommandLine("sys", ">> twitter [opens my twitter profile]");
        recognisedCommand = true;
      }

      if (recognisedCommand === false) {
        addCommandLine("sys", ">> Unrecognised command '" + commandLineInput + "'");
      }

      setPreCommandLineInput(commandLineInput);
      setCommandLineInput("");
    }

    if (event.key === 'ArrowUp') {
      setCommandLineInput(preCommandLineInput);
    }

  }

  const defaultLines = [
    { id: '12', time: new moment(), user: 'sys', text : "   ______     ______     ______     __   __        ______     ______    " },
    { id: '15', time: new moment(), user: 'sys', text : "  /\\  ___\\   /\\  ___\\   /\\  __ \\   /\\ '-.\\ \\      /\\  __ \\   /\\  ___\\   " },
    { id: '16', time: new moment(), user: 'sys', text : "  \\ \\___  \\  \\ \\  __\\   \\ \\  __ \\  \\ \\ \\-.  \\     \\ \\ \\/\\ \\  \\ \\___  \\  " },
    { id: '18', time: new moment(), user: 'sys', text : "   \\/\\_____\\  \\ \\_____\\  \\ \\_\\ \\_\\  \\ \\_\\' \\_\\     \\ \\_____\\  \\/\\_____\\ " },
    { id: '19', time: new moment(), user: 'sys', text : "    \\/_____/   \\/_____/   \\/_/\\/_/   \\/_/ \\/_/      \\/_____/   \\/_____/ " },
    { id: '20', time: new moment(), user: 'sys', text : "" },
    { id: '32', time: new moment(), user: 'sys', text : ">> Welcome to Sean OS (GNU/Linux 8.28.0-62-generic x86_64)" },
    { id: '64', time: new moment(), user: 'sys', text : "" },
    { id: '99', time: new moment(), user: 'sys', text : ">> Type 'help' for a list of available commands and hit the 'Enter' key" }
  ];

  const [lines, setLines] = useState(defaultLines);
  const [commandLineInput, setCommandLineInput] = useState("");
  const [preCommandLineInput, setPreCommandLineInput] = useState("");

  const onChange = event => setCommandLineInput(event.target.value);

  return (
    <React.Fragment>
    
      <ThemeProvider theme={theme} >

        <CssBaseline />

        <AppBar position="static" color="default" elevation={0} className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <Typography variant="h6" color="inherit" noWrap className={classes.toolbarTitle}>
              sean-harrison.net
            </Typography>
            <nav>
              <Grid container justify="center">
              
                <IconButton aria-label="https://github.com/seanaharrison" onClick={() => window.open('https://github.com/seanaharrison')}>
                  <GitHubIcon fontSize="large" />
                </IconButton>
                
                <IconButton aria-label="https://twitter.com/sean__harrison" onClick={() => window.open('https://twitter.com/sean__harrison')}>
                  <TwitterIcon fontSize="large" />
                </IconButton>

                <IconButton aria-label="http://ie.linkedin.com/in/seanaharrison" onClick={() => window.open('http://ie.linkedin.com/in/seanaharrison')}>
                  <LinkedInIcon fontSize="large" />
                </IconButton>
              
              </Grid>
            </nav>
          </Toolbar>
        </AppBar>

        <main>
          <Container maxWidth="xl" component="main">
            
            <div className="stream">

              {lines.map(content => {
                  return <div className="line editline" key={content.id}>
                    <pre className="time">            
                      [<Moment format="hh:mm:ss">{content.time}</Moment>]
                    </pre>
                    <pre className="name">{content.user}:~$</pre>
                    <pre className="information red">{content.text}</pre>
                  </div>
              })}

              <div className="line editline">
                <pre className="time">        
                    [<Moment format="hh:mm:ss" interval={1000} fromNow />]
                </pre>
                <pre className="name">usr:~$</pre>
                <input contentEditable="true" autoComplete="off" autoCorrect="off" autoCapitalize="off" 
                  spellCheck="false" className="information edit" autoFocus="on"
                  value={commandLineInput} onKeyDown={handleKeyDown} onChange={onChange}></input>
              </div>

            </div>

          </Container>
        </main>

        <footer className={classes.footer}>
          <Copyright />
        </footer>

      </ThemeProvider>

    </React.Fragment>
    );
}

export default App;
